<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg">
   <defs>
    None
    <stop :stop-color="color1" offset="0"/>
    <stop :stop-color="color2" offset="1"/>
   </defs>
  </svg>
  <g id="Empty" transform="translate(-450 -528)">
   <rect :fill="background" class="cls-1" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(450 528)" width="60"/>
   <rect :fill="background" data-name="Rectangle 31" height="6" id="Rectangle_31" rx="3" transform="translate(477 555)" width="6"/>
  </g>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>